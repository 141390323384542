import React, { PureComponent } from "react"
import { withPrefix } from "gatsby"
// import browserLang from "browser-lang"
// import locales from "../constants/locales"
export default class Redirect extends PureComponent {
  constructor(props) {
    super(props)
    const { pathname } = props.location

    // Skip build, Browsers only
    if (typeof window == "undefined") {
      return
    }

    // const languages = Object.keys(locales)
    // const detected = window.localStorage.getItem("language") ||
    // browserLang({
    //   languages,
    //   fallback: "en",
    // })

    const detected = window.localStorage.getItem("language") || "en"

    const newUrl = withPrefix(`/${detected}${pathname}`)
    console.log(newUrl)
    window.localStorage.setItem("language", detected)
    window.location.replace(newUrl)
  }
  render() {
    return <div />
  }
}
